import { HydrateOption } from '@core/enums';
import Flicking, { ALIGN } from '@egjs/react-flicking';
import { Grid } from '@helpers/grid';
import withWidget from '@hoc/withWidget';
import DocumentCard from '@stories/Components/Cards/DocumentCard/DocumentCard';
import Heading from '@stories/Components/Global/Typography/Heading';
import React, { useRef } from 'react';
import { DocumentListStyles } from './DocumentList.styles';
const DocumentList = (props) => {
    const sliderRef = useRef(null);
    return (React.createElement(DocumentListStyles.Wrapper, null,
        React.createElement(Grid.Default, null,
            React.createElement(DocumentListStyles.Inner, null,
                React.createElement(Heading, { visual: "h5", semantic: "h2" }, props.title),
                React.createElement(DocumentListStyles.Slider, null,
                    React.createElement(Flicking, { ref: sliderRef, align: ALIGN.PREV }, props.documents.map((x, i) => (React.createElement(DocumentListStyles.SlideItem, { key: i },
                        React.createElement(DocumentCard, { key: i, document: x }))))))))));
};
export default withWidget(DocumentList, 'DocumentList', { hydrate: HydrateOption.InView });
